import { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import {
  setCodeInfo as setCodeInfoAction,
  setError,
  setFlowStage,
  setIsRegister,
} from 'context/state/reducer';
import { AppContext } from 'context/GlobalContextProvider';
import { setAccountCookie, uuidv4 } from 'lib/utils/helper';
import { useBoolean } from 'lib/hooks';
import { Profile } from 'lib/types/profileType';
import useFlowManager from 'lib/hooks/useFlowManager';

import {
  ACCOUNT_COOKIE_NAME_FOX,
  CODE_TYPE_API,
  COOKIE_CODE_INFO,
  FLOW_STAGE_MVPD_SELECT,
  FLOW_STAGE_PROFILE_AUTH,
  ROUTE_ACCOUNT,
  ROUTE_HOME,
  ROUTE_MVPD,
} from 'constants/index';

type Props = {
  profile: Profile;
  setRegCodeError: (b: boolean) => boolean | void;
};

const useRegCodeInput = ({
  profile,
  setRegCodeError,
}: Props): {
  handleSubmit: (regCode: string) => Promise<void>;
  setRegCode: (value: ((prevState: string) => string) | string) => void;
  regCode: string;
  error: boolean;
} => {
  const { dispatch } = useContext(AppContext);
  const [regCode, setRegCode] = useState('');
  const { value: error, setTrue: setErrorTrue, setFalse: setErrorFalse } = useBoolean(false);
  const router = useRouter();
  const { cookies, setCookie, removeCookie } = useFlowManager();

  const handleSubmit = async (regCode: string) => {
    setErrorFalse();
    setRegCodeError(false);
    removeCookie(COOKIE_CODE_INFO);

    let response;

    try {
      response = await profile.validateRegCode(regCode);
      const {
        firstScreenDeviceId,
        isMvpd,
        isRegister,
      }: {
        firstScreenDeviceId: string;
        isAnonymous: boolean;
        isMvpd: boolean;
        isRegister: boolean;
      } = response;

      const info = {
        code: regCode,
        deviceId: uuidv4(),
        type: CODE_TYPE_API,
        ...response,
      };

      setRegCode(regCode);

      setCookie(COOKIE_CODE_INFO, info, { path: ROUTE_HOME });
      dispatch(setCodeInfoAction(cookies[COOKIE_CODE_INFO]));

      // Now & Sports flow
      if (isRegister) dispatch(setIsRegister(isRegister));
      if (isMvpd) {
        // set Anonymous Account Cookie
        const account = await profile.loginAnonymously(firstScreenDeviceId);
        setAccountCookie(account);
        dispatch(setFlowStage(FLOW_STAGE_MVPD_SELECT));
        await router.push(ROUTE_MVPD);
      } else {
        dispatch(setFlowStage(FLOW_STAGE_PROFILE_AUTH));
        // remove Anonymous Account Cookie to go get a Profile Account cookie
        removeCookie(ACCOUNT_COOKIE_NAME_FOX);
        await router.push(ROUTE_ACCOUNT);
      }
    } catch (e) {
      dispatch(setError(e));
      dispatch(setIsRegister(false));
      const info = {
        code: regCode,
        ...response,
      };

      setCookie(COOKIE_CODE_INFO, info, { path: ROUTE_HOME });
      setRegCodeError(true);
      setErrorTrue();
    }
  };

  return {
    regCode,
    setRegCode,
    handleSubmit,
    error,
  };
};

export default useRegCodeInput;
