import styled from 'styled-components';
import color from 'styles/colors';

type ButtonType = {
  hollow?: boolean;
  kind?: string;
};

const Button = styled.button<ButtonType>`
  font: inherit;
  background: ${color.white};
  color: ${color.black};
  position: relative;
  display: flex;
  width: 100%;
  max-width: 340px;
  height: 44px;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  border: 1px solid ${color.endeavour};
  margin: 20px auto;
  border-radius: 22px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.25px;
  outline: 0;
  transition: ease all 200ms;
  &:hover {
    background: ${color.button.background};
    color: ${color.button.text};
    border: ${color.button.background};
    transition: ease all 200ms;
  }
  &:disabled {
    background: ${color.button.background};
    cursor: initial;
    color: ${color.button.disabled};
  }
`;

export default Button;
